import React from 'react';
import Linkify from 'linkifyjs/react';
import styled from 'styled-components';
import {IconEye, IconTime, IconClose, Vk, Facebook, Twitter, Telegram} from '../icons';
import YouTube from 'react-youtube';
import Tag from './tag';
import Moment from 'react-moment';
import {openWindow} from '../utils';
import {origin} from '../utils';

const CardDetailWraper = styled.div`
    display: block;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    box-shadow: ${({theme}) => theme.shadow.base};
    z-index: 88;
    background-color: ${({theme}) => theme.colors.navbar};
    transition: all 0.2s;
    ${({variant}) => variant === 'jumbo' && `
        position: relative;
        height: 645px;
        z-index: 66;
        
        @media (max-width: 768px) {
          height: 280px;
        }
        
        & h2 {
              text-shadow: 0 1px 2px #000000;
              font-family: RobotoSlab;
              font-size: 42px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.94;
              letter-spacing: normal;
              color: ${({theme}) => theme.colors.title};
              z-index: 99;
        }
    `}
     @media(max-width: 576px) {
         margin: 0 10px;
         & h2 {
              font-size: 18px;
        }
    }
`;

export const Info = styled.div`
    font-family: Roboto;
    font-size: 13px;  
    text-align: right; 
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: -0.49px; 
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
    @media(max-width: 768px) {
        text-align: left; 
    }
`;

export const Views = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-height: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.86;
    letter-spacing: 0.58px;
    div {
        font-size: 16px;
        margin: 6px 0 0 0;
    }
    & svg {
        height: 30px;
        path {
            fill: ${({theme}) => theme.colors.light};
        }
    }
    @media(max-width: 768px) {
        justify-content: start;

        div {
            font-size: 12px;
            margin: 6px 0 0 0;
        }
        & svg {
            height: 24px;
            path {
                fill: ${({theme}) => theme.colors.light};
            }
        }
    }
`;

const DetailWrapper = styled.div`
    padding: 22px 21px;
    
    & p {
      white-space: pre-line;
      font-family: Roboto;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      font-size: 14px;
    }
       
    @media (min-width: 769px) {
       padding: 40px 60px

      .content{
        margin-left: 23px;
        p {
            font-size: 18px;
        }
      }
    }
    
    .video {
      padding: 40px 60px;
      display: flex;
      flex-direction: row;
      max-width: 100%;
      justify-content: center;
      @media(max-width: 768px) {
            padding: 0;
            height: calc(100vw * 0.47);
      }
    }
    
    img {
        max-width: 100%;
    }
    ${({theme}) => `
        p {
            color: ${theme.colors.font};
        }
        h2 {
            font-size: 42px;
            color: ${theme.colors.title};
            margin: 11px 0px 10px;
            font-family: Roboto Slab;
        }
        img {
            box-shadow: ${theme.shadow.image};
        }
    `}
    
   @media(max-width: 576px) {
        h2 {
            font-size: 18px;
            line-height: 1.17;
            letter-spacing: -0.6px;
        }
   }
`;

const DetailMeta = styled.div`
    display: flex;
    flex-direction: row;
    color: ${({theme}) => theme.colors.light};
    font-size: 12px;
`;

const CenterColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 25px;
    
    @media(max-width: 320px) {
        margin-left: 5px;
    }
`;

const CloseWrapper = styled.a`
    position: absolute;
    right: 24px;
    top: 24px;
    
    & svg {
        height: 22px;
        width: 21px;
    }
`;

const Images = styled.div`
    position: relative;
    padding: 0 0 0 10px;
    &:before {
        position: absolute;
        content: ' ';
        left: 10px;
        top: 0;
        right: 0;
        height: 2px;
        background-color: #979797;
    }
    
    p {
        font-size: 16px !important;
    }
    
    @media(max-width: 768px) {
        padding: 10px 0;
    }
    
    img {
        margin: 40px 0 10px 0;
    }
`;

const Date = styled(Moment)`
      ${({theme}) => `
            display: block;
            color: ${theme.colors.font};
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.07;
            letter-spacing: -0.53px;
      `}
`;

const Share = styled.button`
        outline:0;
       ${({theme, variant}) => `
        height: 20px;
        display: inline;
        background-color: ${theme.colors[variant]};
        width: 170px;
        height: 40px;
        border-radius: 4px;
        border: none;
        a {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
        }
        
        span {
            margin-left: 9px;
        }
      `}
`;

const ShareWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    padding: 30px 0;
    border-bottom: 2px solid #979797;
    margin: 0 -6px;
    ${Share} {
        margin: 0 6px;
    }
    @media(max-width: 768px) {
        margin: 0 -7px;
        ${Share} {
            margin: 0 7px;
            span {
                display: none;
            }
        }
    }
`;


export default function Card({ name, isVideo, videoProvider, videoId, category, description, picture, date, variant, ratingDisplay, timeToRead, onClick, slug, images = []}) {
    return (
        <CardDetailWraper>
            <CloseWrapper onClick={onClick}>
                <IconClose/>
            </CloseWrapper>
            <DetailWrapper>
                <DetailMeta>
                    <Tag>{category || 'Категория'}</Tag>

                    <CenterColumn>
                        <Views>
                            <IconEye/>
                            <div>&nbsp;{ratingDisplay}</div>
                        </Views>
                    </CenterColumn>
                    <CenterColumn>
                        <Views>
                            <IconTime/>
                            <div>&nbsp;{timeToRead} мин.</div>
                        </Views>
                    </CenterColumn>
                </DetailMeta>
                <h2>{name}</h2>
                <Date format="DD.MM.YYYY">{date}</Date>
                <div className="content">
                    <p>
                        <Linkify options={{target: "_blank"}}>
                            {description}
                        </Linkify>
                    </p>

                    {images.length > 0 && (
                        <Images>

                            {images.map((img, key) => (
                                <>
                                <img src={img.imgLink} alt={img.imgDescription}/>
                                <p>
                                    {img.imgDescription}
                                </p>
                                </>
                            ))}
                        </Images>
                    )}


                    {isVideo && videoProvider === 'youtube' &&
                    <YouTube
                        className="video"
                        videoId={videoId}
                    />}
                    <ShareWrapper>
                        <Share variant="vk">
                            <a target="_blank"
                               rel="noopener noreferrer"
                               onClick={openWindow(
                                   `http://vk.com/share.php?url=${origin}/article/${slug}&title=${name}&description=${description.slice(0, 150)}&image=${picture}
`,
                                   `Поделиться в vk`)}
                               href={`http://vk.com/share.php?url=${origin}/article/${slug}&title=${name}&description=${description.slice(0, 150)}&image=${picture}
`}>
                                <Vk/>
                                <span>Поделиться</span>
                            </a>
                        </Share>
                        <Share variant="fb">
                            <a target="_blank"
                               rel="noopener noreferrer"
                               onClick={openWindow(
                                   `https://www.facebook.com/sharer/sharer.php?u=${origin}/article/${slug}`,
                                   `Поделиться в facebook`
                               )}
                               href={`https://www.facebook.com/sharer/sharer.php?u=${origin}/article/${slug}`}>

                                <Facebook/>
                                <span>Поделиться</span>
                            </a>
                        </Share>
                        <Share variant="tg">
                            <a target="_blank" rel="noopener noreferrer"
                               onClick={openWindow(
                                   `https://telegram.me/share/url?url=${origin}/article/${slug}&text=${name}`,
                                   `Поделиться в telegram`
                               )}
                               href={`https://telegram.me/share/url?url=${origin}/article/${slug}&text=${name}`}>

                                <Telegram/>
                                <span>Поделиться</span>
                            </a>
                        </Share>
                        <Share variant="tw">
                            <a target="_blank"
                               rel="noopener noreferrer"
                               onClick={openWindow(
                                   `http://twitter.com/share?url=${origin}/article/${slug}&text=${name}`,
                                   `Поделиться в twitter`
                               )}
                               href={`http://twitter.com/share?url=${origin}/article/${slug}&text=${name}`}>

                                <Twitter/>
                                <span>Твитнуть</span>
                            </a>
                        </Share>

                    </ShareWrapper>
                </div>
            </DetailWrapper>
        </CardDetailWraper>
    );
}