import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        transform="translate(2 2)"
      >
        <path
          d="M6.60756931 14.278267L7.12131446 14.278267 7.12131446 16.337899 6.60756931 16.337899z"
          transform="rotate(-150.431 6.864 15.308)"
        />
        <path
          d="M4.30346307 12.0040341L4.81743393 12.0040341 4.81743393 14.0629409 4.30346307 14.0629409z"
          transform="rotate(-120.337 4.56 13.033)"
        />
        <path
          d="M5.76066018 4.25563404L7.81991524 4.25563404 7.81991524 4.76957573 5.76066018 4.76957573z"
          transform="rotate(59.635 6.79 4.513)"
        />
        <path
          d="M8.91069627 15.8645655L10.970276 15.8645655 10.970276 16.3787628 8.91069627 16.3787628z"
          transform="rotate(-90.378 9.94 16.122)"
        />
        <path
          d="M3.49093294 6.55632433L5.55015324 6.55632433 5.55015324 7.0704899 3.49093294 7.0704899z"
          transform="rotate(29.572 4.52 6.813)"
        />
        <path
          d="M2.64248432 9.61783143L4.7025008 9.61783143 4.7025008 10.1324867 2.64248432 10.1324867z"
          transform="rotate(-.229 3.672 9.875)"
        />
        <path
          d="M11.8855316 15.0521681L13.9448535 15.0521681 13.9448535 15.5658939 11.8855316 15.5658939z"
          transform="rotate(-119.577 12.915 15.309)"
        />
        <path d="M16.883 2.897A9.825 9.825 0 009.89 0a9.824 9.824 0 00-6.993 2.897A9.825 9.825 0 000 9.89a9.825 9.825 0 002.897 6.993 9.825 9.825 0 006.993 2.896 9.826 9.826 0 006.993-2.896 9.826 9.826 0 002.896-6.993 9.826 9.826 0 00-2.896-6.993zm.232 6.725l-.002.515-2.06-.009.002-.514 2.06.008zm-1.088-3.54l.254.448-1.791 1.016-.254-.447 1.791-1.017zm-2.74-2.587l.443.26-1.04 1.776-.444-.26 1.041-1.776zm2.642 12.434a8.484 8.484 0 01-6.04 2.502 8.484 8.484 0 01-6.04-2.502 8.484 8.484 0 01-2.501-6.04c0-2.28.888-4.426 2.502-6.039a8.485 8.485 0 016.04-2.502l.05.001V9.89l7.317 4.326a8.59 8.59 0 01-1.328 1.713z" />
      </g>
    </svg>
  );
}

export default Icon;
