import * as types from '../actions/types';

const initialState = [];

// static reducer
// popular articles are taken from the server
export default function popular (state = initialState, {type, payload}) {
    switch (type) {
        case types.INITIAL_POPULAR:
            return payload;
        default:
            return state;
    }
}
