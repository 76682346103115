import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fill="#666"
      version="1.1"
      viewBox="0 0 624 624"
      xmlSpace="preserve"
    >
      <path d="M505.943 6.058c-8.077-8.077-21.172-8.077-29.249 0L6.058 476.693c-8.077 8.077-8.077 21.172 0 29.249A20.612 20.612 0 0020.683 512a20.614 20.614 0 0014.625-6.059L505.943 35.306c8.076-8.076 8.076-21.171 0-29.248z" />
      <path d="M505.942 476.694L35.306 6.059c-8.076-8.077-21.172-8.077-29.248 0-8.077 8.076-8.077 21.171 0 29.248l470.636 470.636a20.616 20.616 0 0014.625 6.058 20.615 20.615 0 0014.624-6.057c8.075-8.078 8.075-21.173-.001-29.25z" />
    </svg>
  );
}

export default Icon;
