import styled from 'styled-components';

export default styled.button`
  width: ${({fullWidth}) => fullWidth ? '100%' : '222px'};
  height: 48px;
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.shadow.primary};
  background-color: ${({theme}) => theme.colors.primary};
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: 0.89px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  
  &:hover{
        transform: scale(1.1);
  }
  
  &:disabled {
        background-color: ${({theme}) => theme.colors.light};
        box-shadow: ${({theme}) => theme.shadow.base};
  }
  &:disabled:hover{
        transform: scale(1);
  }
`;