import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-646 -1977)">
          <g transform="translate(615 1966)">
            <path d="M49.99 11.795l-2.714 16.286a.668.668 0 01-.34.477.65.65 0 01-.582.032l-4.803-1.962-2.566 3.128a.624.624 0 01-.52.244.573.573 0 01-.233-.043.665.665 0 01-.323-.249.662.662 0 01-.122-.387v-3.7l9.16-11.228L35.613 24.2l-4.188-1.718c-.261-.098-.403-.293-.424-.583-.014-.282.1-.49.34-.625l17.642-10.179a.649.649 0 01.34-.095c.14 0 .268.039.38.117.234.17.33.395.287.678z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
