import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M0.133275862 0.108070175L19.6824138 0.108070175 19.6824138 20 0.133275862 20z"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(2 2)"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#312D2D"
          d="M13.772 8.434l-.014-.005-4.65 3.653 4.664 1.552v2.203l-7.275-2.423v-2.18l-.01-.012.01-.008v-.002H6.5l4.65-3.652-4.652-1.548V3.809l7.275 2.423v2.202zM9.908.108C4.524.108.133 4.576.133 10.054S4.524 20 9.908 20s9.775-4.468 9.775-9.946S15.292.108 9.908.108z"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}

export default Icon;