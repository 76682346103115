import {useRef, useState, useEffect} from 'react';

export default function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, openModal] = useState(initialIsVisible);
    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            openModal(false);
        }
        if (!ref || !ref.current) {
            openModal(false)
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return [ ref, isComponentVisible, openModal ];
}