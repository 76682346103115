export const dark = {
    id: 0,
    colors: {
        bg: "#1c1c1c",
        navbar: "#232323",
        font: "#fff",
        footer: '#232323',
        light: '#e2e2e2',
        primary: '#de3c3b',
        sub: 'rgba(35, 35, 35, 0.9)',
        title: 'rgb(240, 240, 240)',
        vk: '#4a76a8',
        fb: '#4267b2',
        tg: '#2ca5e0',
        tw: '#1da1f2',
        dz: '#000000',
    },
    shadow: {
        base: "0 2px 6px 0 #141414",
        primary: "0 2px 4px 0 #770e2f",
        light: "0 1px 2px 0 rgba(50, 50, 50, 0.5)",
        dark: "0 1px 4px 0 rgba(81, 81, 81, 0.5)",
        hover: "0 2px 12px 5px #141414",
        image: "0 4px 16px 0 rgba(0, 0, 0, 0.15)",
        red: "0 4px 8px 0 rgba(119, 14, 47, 0.4)",
    }
};

export const light = {
    id: 1,
    colors: {
        bg: "#f4f4f4",
        navbar: "#fff",
        font: "#312d2d",
        footer: "#ebebeb",
        light: '#312d2d',
        primary: '#e71c5c',
        sub: 'rgba(255, 255, 255, 0.9)',
                title: 'rgb(65, 65, 65)',

        vk: '#4a76a8',
        fb: '#4267b2',
        tg: '#2ca5e0',
        tw: '#1da1f2',
        dz: '#000000',
    },
    shadow: {
        base: "0 2px 4px 0 rgba(95, 95, 95, 0.39)",
        primary: "0 2px 4px 0 #770e2f",
        light: "0 2px 4px 0 rgba(182, 182, 182, 0.5)",
        dark: "0 1px 2px 0  rgba(129, 129, 129, 0.5)",
        hover: "0 2px 12px 5px rgba(95, 95, 95, 0.39)",
        image: "0 4px 16px 0 rgba(0, 0, 0, 0.15)",
        red: "0 4px 8px 0 rgba(119, 14, 47, 0.4)",
    }
};

export default light;