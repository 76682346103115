// detail articles
export const GET_ARTICLE_START = 'GET_ARTICLE_START';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR';

// list articles
export const GET_ARTICLE_PORTIONS_START = 'GET_ARTICLE_PORTION_START';
export const GET_ARTICLE_PORTIONS_SUCCESS = 'GET_ARTICLE_PORTION_SUCCESS';
export const GET_ARTICLE_PORTIONS_ERROR = 'GET_ARTICLE_PORTIONS_ERROR';

// list articles
export const GET_POPULAR_PORTIONS_START = 'GET_POPULAR_PORTIONS_START';
export const GET_POPULAR_PORTIONS_SUCCESS = 'GET_POPULAR_PORTIONS_SUCCESS';
export const GET_POPULAR_PORTIONS_ERROR = 'GET_POPULAR_PORTIONS_ERROR';

// video articles
export const GET_VIDEO_PORTIONS_START = 'GET_VIDEO_PORTIONS_START';
export const GET_VIDEO_PORTIONS_SUCCESS = 'GET_VIDEO_PORTIONS_SUCCESS';
export const GET_VIDEO_PORTIONS_ERROR = 'GET_VIDEO_PORTIONS_ERROR';

// search articles
export const SEARCH_ARTICLES_START = 'SEARCH_ARTICLES_START';
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';
export const SEARCH_ARTICLES_ERROR = 'SEARCH_ARTICLES_ERROR';

export const UNSELECT_ARTICLE = 'UNSELECT_ARTICLE';

export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_ERROR = 'GET_RECOMMENDATIONS_ERROR';

export const ARTICLE_RESET = 'ARTICLE_RESET';

// for server-sider rendering
export const INITIAL_CATEGORY = 'INITIAL_CATEGORY';
export const INITIAL_POPULAR = 'INITIAL_POPULAR';

// set popular filter
export const SET_POPULAR_FILTER = 'SET_POPULAR_FILTER';