import styled from 'styled-components';
import {Grid, Row, Col} from 'react-flexbox-grid';


const Layout = styled(Grid)`
    padding:  0px;
    margin:  0px;
`;

const CardRow = styled(Row)`
    padding: 0px;
    margin:  0px;
`;

const Column = styled(Col)`
    padding:  8px;
    @media(max-width: 480px) {
        padding: 8px 2px;
    }
`;

export {
    Layout, CardRow, Column,
}