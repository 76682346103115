import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 24 22">
      <path
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M12 15a3 3 0 110-6 3 3 0 010 6m0-9C5.374 6 0 12 0 12s5.374 6 12 6c6.628 0 12-6 12-6s-5.372-6-12-6"
      />
    </svg>
  );
}

export default Icon;
