import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="19"
      viewBox="0 0 9 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-280 -1977)">
          <g transform="translate(251 1966)">
            <path d="M34.985 30h-3.99v-9.501H29v-3.274h1.994V15.26c0-2.671 1.125-4.26 4.325-4.26h2.663v3.275h-1.665c-1.245 0-1.327.457-1.327 1.311l-.006 1.639H38l-.353 3.274h-2.663V30z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
