import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-834 -1978)">
          <g transform="translate(797 1966)">
            <path d="M54.644 14.71a4.295 4.295 0 001.803-2.393 7.957 7.957 0 01-2.606 1.05A3.994 3.994 0 0050.846 12c-2.265 0-4.101 1.938-4.101 4.327 0 .34.034.67.104.986-3.409-.181-6.432-1.902-8.457-4.522a4.499 4.499 0 00-.555 2.177c0 1.5.724 2.825 1.825 3.603a3.956 3.956 0 01-1.859-.541v.053c0 2.097 1.413 3.847 3.293 4.243a3.82 3.82 0 01-1.083.152c-.264 0-.522-.026-.77-.077.52 1.719 2.036 2.972 3.832 3.005A7.966 7.966 0 0137 27.2a11.188 11.188 0 006.29 1.944c7.548 0 11.673-6.594 11.673-12.313 0-.188-.002-.375-.01-.56A8.577 8.577 0 0057 14.03a7.864 7.864 0 01-2.356.68z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
