import React from 'react';
import styled from 'styled-components';
import Container from './container';
import {Vk, Twitter, Telegram, YandexDzen, Facebook} from '../icons';
import {Link} from 'react-router-dom';

const LogoLink = styled(Link)`
    margin: 0 !important;
    display: block;
    height: 42px;
    width: 42px;
    background-image: url('${process.env.PUBLIC_URL}/assets/logo_icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StoresWrapper = styled.div`
    display: flex;
    flex-direction: row;
    transitions: all 0.2s;
    margin: 0 -6px;
    & a {
        img {
            width: 117px;
        }
        margin: 0 6px;
        opacity: 1;
            &:hover {
            opacity: 0.9;
        }
    }
    @media(max-width: 1024px) and (min-width: 767px) {
        flex-direction: column;
    }
`;

function Stores({ios, android}) {
    return (
        <StoresWrapper>
            {android && <a href={android} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/assets/share_googleplay_icon.png`} alt="google play"/>
            </a>}
            {ios && <a href={ios} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/assets/share_appstore_icon.png`} alt="app store"/>
            </a>}
        </StoresWrapper>
    )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  
  box-shadow: ${({theme}) => theme.shadow.base};
  background-color: ${({theme}) => theme.colors.footer};
  transition: all 0.2s;
  color: ${({theme}) => theme.colors.light};
  height: 240px;
  padding: 2em;
  & svg {
      width: 36px;
      height: 36px;
  }
  
  @media (max-width: 768px) {
      height: 100%;
      position: relative;
  }
`;

const Layout = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const MapColumn = styled.div`
    width: 30%;
    
    @media (max-width: 768px) {
        width: 100%;
    }
    
    ul {
        padding-left: 0;
        margin: 0;
        li {
            list-style-type: none;
            font-family: Roboto;
              font-size: 18px;
              font-weight: bold;
              font-style: normal;
              font-stretch: condensed;
              line-height: 2;
              letter-spacing: normal;
              
              ${({theme}) => `
                 color: ${theme.colors.light};
                 & a {
                  transition: all 0.2s;
                  color: ${theme.colors.light};
                     &:hover {
                        color: ${theme.colors.primary};
                     }
                  }
              `};
             
              
        }
    }
`;

const SocialColumn = styled.div`
    width: 20%;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SocialMedia = styled.div`

    & a svg {
        height: 25px;
        width: 25px;
        margin-right: 4px;
        path {
            transition: all 0.2s;
            fill: ${({theme}) => theme.colors.font};
        }
    }
    
    & a:hover svg{
        path {
            fill: ${({theme}) => theme.colors.primary};
        }
    }
`;

const Description = styled.div`
    width: 50%;
    
    @media (max-width: 768px) {
        width: 100%;
    }
    
    p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: ${({theme}) => theme.colors.light};
    }
`;

const LogoWrapper = styled.div`
  font-family: Roboto Slab;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  color: ${({theme}) => theme.colors.light};
  & span {
        margin-left: 4px
        display: flex;
        align-items: center;
        justify-content: center;
  }
`;

export const socialMedia = {
    fb: 'https://www.facebook.com/stakanchik.media/',
    vk: 'https://vk.com/stakanchik.media',
    tw: '',
    tg: 'https://t.me/stakanchikmedia',
    dz: '',
};

export const stores = {
    ios: "https://apps.apple.com/ru/app/стаканчик/id795390431",
    android: "https://play.google.com/store/apps/details?id=com.stakan4ik.root.stakan4ik_android&hl=ru"
};

const Footer = () => (
    <Wrapper>
        <Container>
            <Layout>
                <Description>
                    <LogoWrapper>
                        <LogoLink to="/"/>
                        <span>Стаканчик</span>
                    </LogoWrapper>
                    <p>
                        © 2015 — 2024 stakanchik.media
                    </p>
                    <p>Использование материалов сайта разрешено только с предварительного письменного согласия правообладателей.
                       Права на картинки и тексты принадлежат авторам. 
                       Сайт может содержать контент, не предназначенный для лиц младше 16 лет.
                    </p>
                </Description>
                <MapColumn>
                    <ul>
                        <li>
                            <Link to="/about">
                                О нас
                            </Link>
                        </li>
                        <li>
                            <a href="/become-a-part">
                                Стать частью проекта
                            </a>
                        </li>
                        <li>
                            <a href="/about">
                                Реклама
                            </a>
                        </li>
                    </ul>
                </MapColumn>
                <SocialColumn>
                    <SocialMedia>
                        {socialMedia.vk && <a href={socialMedia.vk} target="_blank" rel="noopener noreferrer">
                            <Vk/>
                        </a>}
                        {socialMedia.fb && <a href={socialMedia.fb} target="_blank" rel="noopener noreferrer">
                            <Facebook/>
                        </a>}
                        {socialMedia.tg && <a href={socialMedia.tg} target="_blank" rel="noopener noreferrer">
                            <Telegram/>
                        </a>}
                        {socialMedia.tw && <a href={socialMedia.tw} target="_blank" rel="noopener noreferrer">
                            <Twitter/>
                        </a>}
                        {socialMedia.dz && <a href={socialMedia.dz} target="_blank" rel="noopener noreferrer">
                            <YandexDzen/>
                        </a>}
                    </SocialMedia>
                    <p>Приложение Стаканчик в App Store и Google Play</p>
                    <Stores {...stores} small={true}/>
                </SocialColumn>
            </Layout>
        </Container>
    </Wrapper>
);

export default Footer;