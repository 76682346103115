import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import article from './article';
import category from './category';
import popular from './popular';

export default function reducers (history) {
    return  combineReducers({
        router: connectRouter(history),
        category: category,
        popular: popular,
        article,
    })
};
