import styled from 'styled-components';

const Island = styled.div`
    display: block;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    box-shadow: ${({theme}) => theme.shadow.image};
    z-index: 88;
    padding: 40px 33px;
    background: ${({theme}) => theme.colors.navbar};
`;

export default Island;