import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        opacity="0.9"
      >
        <path
          fill="#414141"
          d="M106.532 96.354l23.646 21.468c5.589 5.744-5.434 16.767-11.178 11.178l-23.646-21.468c-19.252 13.817-46.11 11.799-63.032-5.124-19.096-19.096-19.096-49.99 0-69.086 19.096-19.096 49.99-19.096 69.086 0 16.923 16.922 18.94 43.78 5.124 63.032zm-63.187-52.01c-12.886 13.042-12.886 34 0 46.886 13.04 13.041 34 13.041 46.885 0 13.041-12.886 13.041-33.844 0-46.885-12.886-12.886-33.844-12.886-46.885 0z"
        />
        <path
          stroke="#535353"
          strokeLinecap="round"
          strokeWidth="8"
          d="M82.763 81.5c-8.982-8.982-23.544-8.982-32.526 0"
        />
      </g>
    </svg>
  );
}

export default Icon;
