import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M20.831 7.033h-3.366l-.797-2.205A1.255 1.255 0 0015.488 4H8.512c-.528 0-1 .331-1.18.828l-.797 2.205H3.169A2.169 2.169 0 001 9.203v9.39c0 1.198.971 2.169 2.169 2.169H20.83A2.169 2.169 0 0023 18.593v-9.39a2.169 2.169 0 00-2.169-2.17zM12 18.183a4.595 4.595 0 110-9.19 4.595 4.595 0 010 9.19z"
      />
    </svg>
  );
}

export default Icon;
