import { css } from 'styled-components';

const navbarStyles = css`
  .headroom-wrapper {
    position: fixed;
    width: 100%;
    z-index: 9999;
    
  }
  .headroom {
    z-index: 9999 !important;
    width: 100%;
    svg {
      height: 2rem;
      g {
        fill: #333;
      }
    }
    
    & .subnavbar {
        height: auto;
        transition: all 0.25s ease-in-out;
        box-shadow: none;
        top: -80px;
        opacity: 0;
    }
    
    & .subnavbar.show {
        top: 0;
        opacity: 1;
    }
    
    nav {
        height: 55px;
    }
    
    nav.actions {
        svg {
               height: 24px;
               path {
                    fill: ${({theme}) => theme.colors.primary};
               }
         }
        display: flex;
        justify-content: flex-end;
        min-width: 195px; 
        @media(max-width: 768px) {
            min-width: auto;
            justify-content: space-between;
        }

    }    
    nav.links {
        a {
            padding: 16px 2px 12px;
            border-bottom: 4px solid rgba(0,0,0,0); 
        }
        
        a:hover {
            border-bottom: 4px solid ${({theme}) => theme.colors.primary};
        }
        
        a:active, a.active {
            font-weight: bold;
            border-bottom: 4px solid ${({theme}) => theme.colors.primary}; 
        }  
    }
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
    height: 55px;
    
    @media(max-width: 768px) {
        & .subnavbar {
            top: 0;
            opacity: 1;
        }
    }
  }
  .headroom--scrolled {
    transition: all 0.25s ease-in-out;
    
    & .subnavbar {
        top: 0;
        opacity: 1;
        position: relative;
        
        @media(max-width: 768px) {
            position: absolute;
        }
    }
  }
  .headroom--unpinned {
    position: fixed;
    //transform: translateY(-100%);
    transition: all 0.25s ease-in-out;
    
    & .subnavbar {
        height: auto;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        
        @media(max-width: 768px) {
            .hamburger {
                top: -80px;
                opacity: 0;
            }
        }
    }
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
        
    & .hamburger {
        @media(max-width: 768px) {
            position: absolute;
            display: block !important;
        }
    }
    
    //box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    z-index: 9999 !important;
    // nav {
    //   a {
    //  
    //     &:hover {
    //       color: #0063FF;
    //      
    //     }
    //     &:focus {
    //       color: #0063FF;
    //     }
    //   }
    // }
    svg {
      height: 2rem;
      g {
        fill: #333;
      }
    }
  }
`;

export default navbarStyles;
