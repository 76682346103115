import React from 'react';
import styled from 'styled-components';

const Input = styled.div`
    background: #fff;
    flex-direction: row;
    box-shadow: 0 1px 2px 0 rgba(129, 129, 129, 0.5);
    border-radius: 16px;
    width: 100%;
    margin: 5px 0;
    // search input
    & input {
         padding: 7px;
         position: relative;
         display: inline-block;
         box-sizing: content-box;
         height: 35px;
         border: 1px solid #ddd;
         text-overflow: clip;
         z-index: 77;
         border-radius: 8px;
         padding: 0 5%;
         width: 90%;
         background-color:  ${({theme}) => theme.colors.navbar};
         color: ${({theme}) => theme.colors.primary};
         &:focus {
             box-shadow: inset 0 0 0px 1px ${({theme}) => theme.colors.primary};
             outline-color: rgba(0,0,0,0);
         }
    }
`;
export default function InputCompo ({...props}) {
    return (
        <Input>
            <input {...props}/>
        </Input>
    )
}
