import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    transitions: all 0.2s;
    margin: 39px -15px 41px -15px;
    & a {
        img {
            width: 236px;
        }
        margin: 0 15px;
        opacity: 1;
            &:hover {
            opacity: 0.9;
        }
    }
    @media(max-width: 576px) {
        & a {
            img {
                width: 117px;
            }
        }
    }
`;

const StoresWrapper = styled.div`
    margin: 0 auto;
    width: 50%;
`;

export default function Stores({ios, android, small}) {
    return (
        <StoresWrapper>
            <Flex small={small}>
            {android && <a href={android} rel="noopener noreferrer" target="_blank">
                <img src={`${process.env.PUBLIC_URL}/assets/share_googleplay_icon.png`} alt="google play"/>
            </a>}
            {ios && <a href={ios} rel="noopener noreferrer" target="_blank">
                <img src={`${process.env.PUBLIC_URL}/assets/share_appstore_icon.png`} alt="app store"/>
            </a>}
            </Flex>
        </StoresWrapper>
    )
}