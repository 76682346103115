import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="13"
      viewBox="0 0 22 13"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-459 -1980)">
          <g transform="translate(433 1966)">
            <path d="M38.079 26.448s.397-.044.6-.262c.186-.2.18-.575.18-.575s-.025-1.757.794-2.016c.807-.255 1.843 1.699 2.943 2.45.83.568 1.461.443 1.461.443l2.939-.04s1.536-.095.808-1.298c-.06-.099-.425-.89-2.184-2.517-1.844-1.703-1.596-1.428.623-4.374 1.351-1.794 1.891-2.889 1.722-3.357-.16-.448-1.154-.33-1.154-.33l-3.308.021s-.245-.033-.427.075c-.178.106-.293.354-.293.354s-.523 1.388-1.221 2.569c-1.473 2.49-2.062 2.622-2.303 2.468-.56-.362-.42-1.45-.42-2.222 0-2.414.368-3.42-.716-3.681-.36-.087-.624-.144-1.544-.153-1.18-.012-2.179.004-2.745.28-.377.183-.667.592-.49.616.219.029.714.132.976.488.34.46.327 1.49.327 1.49s.195 2.843-.455 3.196c-.445.242-1.056-.252-2.37-2.512-.673-1.158-1.18-2.437-1.18-2.437s-.098-.24-.274-.368c-.212-.155-.508-.204-.508-.204l-3.143.02s-.472.014-.645.218c-.154.181-.012.558-.012.558s2.46 5.734 5.248 8.624c2.555 2.65 5.456 2.476 5.456 2.476h1.315z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
