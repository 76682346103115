import * as types from './types';
import {axios} from '../utils/axios';


export const setPopularFilter = (filter) => dispatch => dispatch({
    type: types.SET_POPULAR_FILTER,
    payload: filter,
})

export const articleReset = () => dispatch => dispatch({
    type: types.ARTICLE_RESET,
    payload: {}
});

export const getArticlePortion = ({idCategory, startWith = 0, count = 20}) => async (dispatch) => {
    dispatch({
        type: types.GET_ARTICLE_PORTIONS_START,
        payload: {idCategory, offset: startWith + count}
    });
    try {
        const {data} = await axios.get(`/api/v1/article/portion/`, {params: {
            idCategory,
            startWith,
            count,
        }});
        dispatch({
            type: types.GET_ARTICLE_PORTIONS_SUCCESS,
            payload: data,
        })
    } catch (err) {
        dispatch({
            type: types.GET_ARTICLE_PORTIONS_ERROR,
            payload: err,
        })
    }
};

export const getVideoPortion = ({idCategory, startWith = 0, count = 20}) => async (dispatch) => {
    dispatch({
        type: types.GET_VIDEO_PORTIONS_START,
        payload: {idCategory, offset: startWith + count, onlyWithVideo: true}
    });
    try {
        const {data} = await axios.get(`/api/v1/article/portion/`, {params: {
            idCategory,
            startWith,
            count,
            onlyWithVideo: true
        }});
        dispatch({
            type: types.GET_VIDEO_PORTIONS_SUCCESS,
            payload: data,
        })
    } catch (err) {
        dispatch({
            type: types.GET_VIDEO_PORTIONS_ERROR,
            payload: err,
        })
    }
};

export const changeCategory = (idCategory, onlyWithVideo = false) => async dispatch => {
    dispatch({
        type: types.GET_ARTICLE_PORTIONS_START,
        payload: {idCategory, onlyWithVideo},
    });
    dispatch(getArticlePortion({idCategory, onlyWithVideo, count: 21}))
};

export const getAndSelectArticle = (slug) => async(dispatch) => {
    dispatch({
        type: types.GET_ARTICLE_START,
        payload: {},
    });

    try {
        const {data} = await axios.get(`/api/v1/article/detail/${slug}`);
        dispatch({
            type: types.GET_ARTICLE_SUCCESS,
            payload: data,
        });
        dispatch(getRecommendations(data.id));
    } catch (err) {
        dispatch({
            type: types.GET_ARTICLE_ERROR,
            payload: err,
        })
    }
};

export const selectArticle = (article) => async(dispatch) => {
    dispatch({
        type: types.GET_ARTICLE_SUCCESS,
        payload: article,
    });
    dispatch(getRecommendations(article.id));
};

export const unselectArticle = () => async (displatch) => {
    displatch({
        type: types.UNSELECT_ARTICLE,
    })
};

export const searchArticles = (q) => async (dispatch) => {
    dispatch({
        type: types.SEARCH_ARTICLES_START,
        payload: {},
    });
    try {
        const {data} = await axios.get(`/api/v1/article/search`, {
            params: {q},
        });
        dispatch({
            type: types.SEARCH_ARTICLES_SUCCESS,
            payload: data,
        })
    } catch (err) {
        dispatch({
            type: types.SEARCH_ARTICLES_ERROR,
            payload: err,
        })
    }
};

export const getRecommendations = (idCurrentArticle) => async (dispatch) => {
    try {
        const {data} = await axios.get(`/api/v1/article/recommendations`, {
            params: {
                idCurrentArticle,
                recommendations: 9,
            }
        });
        dispatch({
            type: types.GET_RECOMMENDATIONS_SUCCESS,
            payload: data,
        })
    } catch (err) {
        dispatch({
            type: types.GET_RECOMMENDATIONS_ERROR,
            payload: err,
        })
    }
};