import React from 'react';
import styled, {keyframes} from 'styled-components';
import {CardRow, Column} from './flex';
import {IconEye, IconPhoto, IconVideo} from '../icons';
import Tag from './tag';
import Moment from 'react-moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Info = styled.div`
    font-family: Roboto;
    font-size: 13px;  
    text-align: right; 
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: -0.49px; 
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
    @media(max-width: 768px) {
        text-align: left; 
    }
`;

export const Meta = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 5px 20px;
    z-index: 1000;
    @media(max-width: 768px) {
        padding: 5px 12px; 
    }
    svg {
        path {
            fill: #fff;
        }
    }
`;

export const Views = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-height: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.86;
    letter-spacing: 0.58px;
    & svg {
        height: 18px;
    }
    @media(max-width: 768px) {
        justify-content: start;
    }
    & span {
        padding-top: 2px;
    }
`;

export const Preview = styled.div`
    min-height: 280px;
    cursor: pointer;
    height: 100%;
    position: relative;
    background: url(${`${process.env.PUBLIC_URL}/assets/placeholder.png`}) no-repeat;
    background-size: cover;	
    background-position: center center;
    border-radius: 8px;
    z-index: 88;
    display: flex;
    justify-content: end;
    transition: all 0.2s;
    flex-direction: column-reverse;
    padding-bottom: 12px;
    & img {
        bottom: 0;
        position: absolute;
        object-fit: cover;
        object-position: center center;
        width: 100%
        height: 100%;
        border-radius: 8px;
        transition: all .3s;
        opacity: ${({picture}) => picture ? '1' : '0'};
        z-index: 0;
    }

    &:hover img {
        transform: scale(1.1);
    }
    
    &:hover:before {
        transform: scale(1.1);
    }
    
    &:after{
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        transition: all 0.7s;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.21), rgba(4, 4, 4, 0.4));
        opacity: ${({picture}) => picture ? '1' : '0.2'};
    }

    @media (max-width: 767px) {
        min-height: 180px;
    }
    
    overflow: hidden;
`;

export const CardWraper = styled.div`
    display: block;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    box-shadow: ${({theme}) => theme.shadow.base};
    z-index: 8877;
    overflow: hidden;
    transition: all 0.5s;
    
    &:hover {
        box-shadow: ${({theme}) => theme.shadow.hover};
    }
    
    @media(max-width: 768px) {
        border-radius: 4px;
    }
    
    @media(max-width: 576px) {
        & h2 {
              font-size: 18px;
        }
    }
    
    p.description {
        display: none;
    }
`;

export const Title = styled.div`
    padding: 15px 20px;
    position: relative;
    z-index: 2222;
    border-radius: 0 0 8px 8px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: flex;
    justify-content: center;
    background-color: ${({theme}) => theme.colors.navbar};
    transition: all 0.2s;
    @media (max-width: 768px) {
          padding: 12px 11px;
          font-size: 14px;
          min-height: 48px;
    }
    
    & p {
          font-family: Roboto;
          font-size: 21px;
          font-weight: bold;
          font-style: normal;
          font-stretch: condensed;
          line-height: 1.14;
          margin: 0;
          letter-spacing: -0.33px;
          color: ${({theme}) => theme.colors.font};
          
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: N; /* number of lines to show */
          
          @media (max-width: 768px) {
              line-height: 1.14;
              letter-spacing: -0.22px;
              overflow: hidden;
          }
    }
    @media (max-width: 768px) {
          overflow: hidden;
    }
    
    ${Meta} {
        display: none;
    }
`;

export const Dummy = styled.div`
    flex-grow: 1;
`;

export const Video = styled.div`
    padding: 2px 12px;
    z-index: 99;
    & svg {
        height: 30px;
        width: 30px;
    }
    
    @media(max-width: 768px) {
        padding: 0 2px 6px 0;
        & svg {
            height: 23px;
            width: 23px;
        }
    }
`;


export const CategoryIcon = styled.div`
    position: relative;
    display: flex;
        min-height: 34px;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0 0 0 2px;
    @media(max-width: 576px) {
        padding: 0;
    }
`;

export const Box = Column;

export const Row = styled(CardRow)`
    @media(min-width: 768px) {
        .col-md-6 {
            ${CardWraper} {
                background-color: none;
                ${Title} {
                    z-index: 8888;
                    position: absolute;
                    bottom: 45px;
                    background: none !important;
                    justify-content: end;
                    width: 100%;
                    & p {
                        max-height: 56px;
                        font-size: 24px;
                        color: #fff;
                        -webkit-line-clamp: 2;
                        line-height: 1.08;
                    }
                }
            }
        }
        .col-md-4 {
            ${CardWraper} {
                ${Title} {
                    min-height: 100px;
                    & p {
                        font-size: 21px;
                        line-height: 1.14;
                        letter-spacing: -0.33px
                    }
                }
            }
        }
    }
    @media(max-width: 767px) {
        .col-sm-12 {
            ${CardWraper} {
                background-color: none;
                ${Preview} {
                    min-height: 280px; 
                    ${Meta} {
                        
                        ${Video} {
                            padding: 0 12px;
                        }
                    }
                }
                ${Title} {
                    z-index: 8888;
                    position: absolute;
                    bottom: 60px;
                    background: none;
                    max-height: 78px;
                    line-height: 1.14;
                    letter-spacing: -0.8px;
                    & p {
                        font-size: 21px;
                        color: #fff;
                        max-height: 50px;
                    }
                }
            }
        }
        .col-sm-6 {
            ${CardWraper} {
                ${Meta} {
                    @media(max-width: 768px) {
                        padding: 5px 15px;
                        z-index: 8888;
                        display: block;
                        ${CategoryIcon} {
                            flex-direction: row;
                            width: auto;
                            ${Dummy} {
                                max-width: 15px;
                            }
                            ${Info} {
                                padding-bottom: 5px;
                            }
                        }
                        
                    }
                }
            
                ${Title} {
                    max-height: 72px;
                    min-height: 72px;
                    & p {
                        font-size: 14px;
                        letter-spacing: -0.22px;
                    }
                    ${Meta} {
                        display: none;
                    }
                }
            }
        }
    }
`;

const dummyAnimation = keyframes`
  from {
    background: linear-gradient(to right, #dedede, #cdcccf);
  }

  to {
    background: linear-gradient(to right, #cdcccf, #dedede);
  }
`;


const TextDummy = styled.div`
  width: ${({width}) => width};
  height: 8px;
  margin: 7px 0 7px;
  border-radius: 6px;
  animation: ${dummyAnimation} 1s linear infinite;
`;

const DetailJumboWrapper = styled.div`
    z-index: 0; 
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1120px;
    
    ${CardWraper} {
        ${Preview} {
            min-height: 645px;
            border-radius: 8px;
            &:before {
                border-radius: 8px;
            }
            &:after {
                background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 3%, rgba(52,36,45,0.70) 91%);
            }
            &:hover img {
                transform: scale(1.0);
            }
            ${Meta} {
                display: none;
            }
        }
        ${Title} {
            z-index: 8888;
            position: absolute;
            bottom: 136px;
            background: none;
            padding: 0 35px;
            justify-content: end;
            & p {
                min-height: auto;
                display: -webkit-box;
                padding: 10px 5px;
                font-size: 36px;
                text-shadow: 0 1px 2px #000000;
                font-family: Roboto Slab;      
                font-weight: bold;
                font-style: normal;  
                font-stretch: normal;
                line-height: 0.94;    
                letter-spacing: normal;
                color: #fff;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                max-width: 720px;

            }
            & p.description {
                  padding: 0 5px;
                  white-space: pre-line;
                  max-height: 72px;
                  font-family: Roboto;
                  font-size: 18px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #ffffff;
                  max-width: 720px;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
            }
        }
    }
    @media(max-width: 576px) {
        ${CardWraper} {
            ${Preview} {
                min-height: 280px;
            }
        }
    }
    
    ${TextDummy} {
        display: none;
    }
`;

const JumboWrapper = styled.div`
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1120px;
    @media(min-width: 577px) {
        ${CardWraper} {
            ${Preview} {
                min-height: 645px;
                border-radius: 8px;
                &:before {
                    border-radius: 8px;
                }
                &:after {
                    background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 3%, rgba(52,36,45,0.70) 91%);
                }
                &:hover img {
                    transform: scale(1.0);
                }
                ${Meta} {
                    display: none;
                }
            }
            ${Title} {
                z-index: 8888;
                position: absolute;
                bottom: 136px;
                background: none;
                padding: 0 35px;
                justify-content: end;
                & p {
                    min-height: auto;
                    display: -webkit-box;
                    padding: 10px 5px;
                    font-size: 36px;
                    text-shadow: 0 1px 2px #000000;
                    font-family: Roboto Slab;      
                    font-weight: bold;
                    font-style: normal;  
                    font-stretch: normal;
                    line-height: 0.94;    
                    letter-spacing: normal;
                    color: #fff;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    max-width: 720px;
    
                }
                & p.description {
                      padding: 0 5px;
                      white-space: pre-line;
                      max-height: 72px;
                      font-family: Roboto;
                      font-size: 18px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #ffffff;
                      max-width: 720px;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                }
            }
        }
    }

    @media(max-width: 576px) {
        padding: 4px 20px;
        ${CardWraper} {
            background-color: none;
            ${Preview} {
                min-height: 280px; 
                ${Meta} {
                    
                    ${Video} {
                        padding: 0 12px;
                    }
                }
            }
            ${Title} {
                z-index: 8888;
                position: absolute;
                bottom: 60px;
                background: none;
                max-height: 78px;
                line-height: 1.14;
                letter-spacing: -0.8px;
                & p {
                    font-size: 21px;
                    color: #fff;
                    max-height: 50px;
                }
            }
        }
    }

    @media(max-width: 480px) {
        padding: 4px 4px;
    }
    
    ${TextDummy} {
        display: none;
    }
`;

export function Card({picture, name, description, category, isVideo, images, ratingDisplay, date}) {
    return (
        <CardWraper>
            <Preview picture={picture}>
            <LazyLoadImage src={picture} loading="lazy" alt=".."/>
                <Meta>
                    <CategoryIcon>
                    <Info>
                        {ratingDisplay && <Views>
                            <IconEye fill="#fff"/>
                            <span>&nbsp;{ratingDisplay}</span>
                        </Views> }
                        {date && <Moment format="DD.MM.YYYY">{date}</Moment>}
                    </Info>

                    <Dummy/>
                        {isVideo && <Video>
                            <IconVideo/>
                        </Video> }
                        {images && images.length > 0 && <Video>
                            <IconPhoto/>
                        </Video>}
                    </CategoryIcon>
                    {category && <Tag>{category}</Tag>}
                </Meta>
                <Dummy/>
            </Preview>
            <Title>
                {name ? <p>{name}</p>: <>
                    <TextDummy width="100%"/>
                    <TextDummy width="85%"/>
                    <TextDummy width="15%"/>
                </>
                }
                <p className="description">{description}</p>
            </Title>
        </CardWraper>
    )
}

export function AnimationCard(props) {
    return (
            <Card {...props}/>
    )
}

export function Jumbo(props) {
    return (
        <JumboWrapper>
                <Card {...props}/>
        </JumboWrapper>
    )
}

export function ArticleJumbo(props) {
    return (
        <DetailJumboWrapper>
                <Card {...props}/>
        </DetailJumboWrapper>
    )
}

export {
    Tag,
}