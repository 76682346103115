import styled from 'styled-components';

const Tag = styled.div`
  border-radius: 2px;
  background-color: ${({theme}) => theme.colors.primary};
  padding: 8px 12px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: condensed;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.67px;
  text-align: center;
  color: #ffffff;
  z-index: 1000;
  box-shadow: ${({theme}) => theme.shadow.primary};
  transition: all 0.2s;

  @media(max-width: 768px) {
    max-height: 24px;
    display: inline;
    font-size: 14px;
    border-radius: 2px;
    line-height: 1.14;
    letter-spacing: 0.58px;
    padding: 4px 8px;
  }
`;

export default Tag;