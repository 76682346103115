import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1120px;
  
  width: 100%;
  justify-content: space-around;
  z-index: 99;
  padding: 0 33px;
  
  
  @media(max-width: 768px) {
    padding: 0 12px;
    width: auto;
  }
  
   @media(max-width: 480px) {
    padding: 0 2px;
    width: auto;
  }
  
  ${({type}) => type === 'big' && `
    max-width: 1220px;
    padding: 0;
    
    @media(max-width: 768px) {
      padding: 110px 0 0 0;
    }
  `};
`;

const Container = ({ children, type, className }) => (
  <Wrapper className={className} type={type}>
    {children}
  </Wrapper>
);

export default Container

Container.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['article', 'base', 'big']),
  className: PropTypes.string,
};

Container.defaultProps = {
  type: 'base',
  className: null,
};
