// The basics
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import * as actions from './actions/article';
import {push} from 'connected-react-router';
import Routes from './routes';
import {navbarStyles, resetStyles} from './styles';
import './index.css';
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components';
import {NavbarContext, CategoryContext, PopularContext} from './contexts';
import {Navbar, Footer} from './components';
import {dark, light} from './contexts/theme-context';
import queryString from 'query-string'
import {isServer} from './utils';

const GlobalStyle = createGlobalStyle`
    ${resetStyles}
    ${navbarStyles}

    body {
        background-color: ${({theme}) => theme.colors.bg};
        transition: all 0.2s;
    }
`;

const Content = styled.div`
    position: relative;
    min-height: calc(100vh - 240px);
`;


function App({articleReset, push, location, searchArticles}) {

    const searchInput = queryString.parse(location.search).q || "";

    function onSearchClick(q) {
        if (!q) return;
        searchArticles(q);
        push(`/search/?q=${q}`)
    }

    const [subNavbar, setSubNavbar] = React.useState(null);

    const [theme, setTheme] = React.useState(light);

    React.useEffect(() => {

        !isServer && setTheme(
            localStorage.getItem("theme") === String(dark.id)
                ? dark
                : light);

        if (location.pathname !== "/") {
            setPinStart(0)
        }
        // use subnavbar
        if (['/', '/popular'].findIndex((i) => i === location.pathname) > -1) {
            return
        }

        setSubNavbar(null)
    }, [location]);


    const [lastDays, setLastDays] = React.useState('ALL');
    const [currentCategory, _setCurrentCategory] = React.useState(null);
    const [pinStart, setPinStart] = React.useState(0);

    function setCurrentCategory(c) {
        articleReset();
        _setCurrentCategory(c);
    }


    function onChangeThemeClick() {
        localStorage.setItem("theme", theme.id === dark.id ? light.id : dark.id);
        setTheme(theme.id === dark.id ? light : dark);
    }


    return (
        <ThemeProvider theme={theme}>
            <NavbarContext.Provider value={{
                onSearchClick,
                onChangeThemeClick,
                setPinStart,
                pinStart,
                searchInput,
                setSubNavbar,
                subNavbar,
            }}>
                <CategoryContext.Provider value={{currentCategory, setCurrentCategory}}>
                    <PopularContext.Provider value={{lastDays, setLastDays}}>
                    <GlobalStyle/>
                    <Navbar/>
                    <Content>
                        <Routes location={location}/>
                    </Content>
                    <Footer/>
                    </PopularContext.Provider>
                </CategoryContext.Provider>
            </NavbarContext.Provider>
        </ThemeProvider>)
}

const mapStateToProps = state => state.article;

const mapDispatchToProps = dispatch =>
    bindActionCreators({...actions, push}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
