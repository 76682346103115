import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill="#E71C5C"
          fillRule="nonzero"
          d="M9.988 4.224a8.246 8.246 0 00-3.981 1.114c2.346 1.302 3.895 3.771 3.845 6.64-.05 2.87-1.687 5.395-4.08 6.777a7.84 7.84 0 003.944.98c4.375-.076 7.986-3.61 8.06-7.892.076-4.28-3.414-7.694-7.788-7.62z"
          transform="rotate(29 11.775 11.979)"
        />
    </svg>
  );
}

export default Icon;