import * as types from '../actions/types';

const initialState = {
    popular: [],
    all: {},
};

// static reducer
// categories are taken from the server
export default function category (state = initialState, {type, payload}) {
    switch (type) {
        case types.INITIAL_CATEGORY:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}
