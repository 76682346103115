import * as serviceWorker from './serviceWorker';
import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { ConnectedRouter, push } from 'connected-react-router';
import createStore from './store';
import App from './App';
import './index.css';
import './fonts.css';
import 'animate.css';
import {axios} from './utils/axios';


// Create a store and get back itself and its history object
const { store, history } = createStore();


axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 404) {
            store.dispatch(push('/not-found'))
        }
        if (error.response.status >= 500) {
            store.dispatch(push('/internal-error'))
        }
        return Promise.reject(error);
    }
);


// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <ConnectedRouter history={history} noInitialPop>
      <Frontload noServerRender={true}>
        <App />
      </Frontload>
    </ConnectedRouter>
  </Provider>
);

const root = document.querySelector('#root');

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
