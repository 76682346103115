import styled from 'styled-components';

const Title = styled.h2`
    text-transform: uppercase;
    padding:  32px 8px 20px;
    margin: 0;
    font-family: Roboto;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 0.93;
    letter-spacing: 1px;
    color: #858585;
    
    @media(max-width: 576px) {
        font-size: 18px;
        line-height: 1.44;
        padding:  8px;
        letter-spacing: 0.64px;
    }
`;

export default Title;