import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './home'),
  loading: () => null,
  modules: ['home'],
  webpack: () => [require.resolveWeak('./home')]
});

const Article = Loadable({
  loader: () => import(/* webpackChunkName: "article" */ './article'),
  loading: () => null,
  modules: ['article'],
  webpack: () => [require.resolveWeak('./article')]
});

const Popular = Loadable({
  loader: () => import(/* webpackChunkName: "popular" */ './popular'),
  loading: () => null,
  modules: ['popular'],
  webpack: () => [require.resolveWeak('./popular')]
});


const About = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ './about'),
  loading: () => null,
  modules: ['about'],
  webpack: () => [require.resolveWeak('./about')]
});

const Search = Loadable({
  loader: () => import(/* webpackChunkName: "search" */ './search'),
  loading: () => null,
  modules: ['search'],
  webpack: () => [require.resolveWeak('./search')]
});

const Video = Loadable({
  loader: () => import(/* webpackChunkName: "video" */ './video'),
  loading: () => null,
  modules: ['video'],
  webpack: () => [require.resolveWeak('./video')]
});

const Error404 = Loadable({
  loader: () => import(/* webpackChunkName: "error404" */ './error404'),
  loading: () => null,
  modules: ['error404'],
  webpack: () => [require.resolveWeak('./error404')]
});

const Error500 = Loadable({
  loader: () => import(/* webpackChunkName: "error500" */ './error500'),
  loading: () => null,
  modules: ['error500'],
  webpack: () => [require.resolveWeak('./error500')]
});

const RestorePassword = Loadable({
  loader: () => import(/* webpackChunkName: "restorePassword" */ './restorePassword'),
  loading: () => null,
  modules: ['restorePassword'],
  webpack: () => [require.resolveWeak('./restorePassword')]
});

const BecomePart = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ './becomePart'),
  loading: () => null,
  modules: ['becomePart'],
  webpack: () => [require.resolveWeak('./becomePart')]
})


function Routes({location}) {
    return (
      <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/article/" component={Home}/>
          <Route exact path="/search" component={Search}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/become-a-part" component={BecomePart}/>
          <Route exact path="/video" component={Video}/>
          <Route exact path="/popular" component={Popular}/>
          <Route exact path="/restore/" component={RestorePassword}/>
          <Route exact path="/article/:slug" component={Article}/>
          <Route exact path="/internal-error" component={Error500}/>
          <Route component={Error404}/>
      </Switch>
)}

 export default Routes;
