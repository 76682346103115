// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const isSafari = !isServer && navigator.userAgent.toLowerCase().indexOf('safari/') > -1;


export const openWindow = (href, title, w=800, h=600) => (e) => {
      if (isSafari) {
          return
      }
      e.preventDefault();
      if (!isServer) {
          window.open(href, title, `width=${w} height=${h}`);
          return
      }
};

export const origin = isServer ? process.env.ORIGIN : window.location.origin;