import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="#E71C5C"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M16.165 14.377l5.59 5.589c.893.919-.87 2.682-1.79 1.788l-5.588-5.589c-3.08 2.21-7.378 1.888-10.086-.82a7.811 7.811 0 010-11.054 7.811 7.811 0 0111.054 0c2.708 2.708 3.03 7.005.82 10.086zM6.055 6.055c-2.062 2.087-2.062 5.44 0 7.502 2.087 2.086 5.44 2.086 7.502 0 2.086-2.062 2.086-5.415 0-7.502-2.062-2.062-5.415-2.062-7.502 0z"
        opacity="0.9"
      />
    </svg>
  );
}

export default Icon;