import React from 'react';
import styled from 'styled-components'
import Container from './container';
import Slider from "react-slick";
import {AnimationCard as Card, CardWraper, Title, Meta, Preview, Info, Video} from "./flex-card";
import Head from './title';
import {useWindowSize} from '../hooks'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from  "react-router-dom"
const SorcererWrapper = styled.div`
    margin: 28px 0;
    height: 400px;
    box-shadow: ${({theme}) => theme.shadow.base};
    background-color: ${({theme}) => theme.colors.navbar};
    transition: all 0.2s;
    position: absolute;
    left: 0;
    right: 0;
    
    @media(max-width: 576px) {
        height: 270px;
    }
    
    .slick-dots {
        bottom: -35px;
    }
    
    .slick-dots li button::before {
          font-size: 15px;
          color: #a39a9d;
          opacity: 1;
    }
    
    .slick-dots li.slick-active button::before {
          font-size: 15px;
          color: ${({theme}) => theme.colors.primary};
          opacity: 1;
    }
    
    @media (max-width: 576px) {
        .slick-dots li button::before, .slick-dots li.slick-active button::before {
          font-size: 10px;
        }
    }
`;


const PopularCard = styled.div`
    width: 280px;
    padding: 8px;
    
    @media(min-width: 576px) {
        ${CardWraper} {
            background-color: ${({theme}) => theme.colors.navbar};
            ${Preview} {
                min-height: 247px;
                ${Meta}{
                     ${Video} {
                        display: none !important;
                     }
                }
            }
            
            ${Title} {
                bottom: 45px;
                z-index: 8888;
                position: absolute;
                background: none !important;
                justify-content: end;
                & p {
                    max-height: 45px;
                    color: #fff;
                    -webkit-line-clamp: 2;
                }
            }
        }
    }
    
    @media(max-width: 575px) {
        ${CardWraper} {
            ${Preview} {
                min-height: 155px !important;
                ${Meta} {
                    display: block;
                    z-index: 8888;
                    ${Info} {
                        display: none !important;
                    }
                }
            }
            ${Title} {
                ${Meta} {
                    display: none;
                }
                z-index: 8888;
                position: absolute;
                bottom: 30px;
                background: none !important;
                justify-content: end;
                & p {
                    color: #fff;
                      font-size: 14px !important;
                      line-height: 1.14 !important;
                }
            }
            
        }              
    }
`;

const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
};


export default function Sorcerer ({articles, variant, article, onCardClick}) {
    const {width} = useWindowSize();
    switch (variant) {
        case 'popular':
            return (
                <SorcererWrapper>
                    <Container>
                        <Head>Популярное в категориях</Head>
                        <Slider {...settings}
                                slidesToShow={width >= 1024 ? 4 : 2}
                                slidesToScroll={width >= 1024 ? 4 : 2}>
                            {articles.map((article, key) => (
                                <PopularCard>
                                     <Link to={{pathname: `/article/${article.slug}`}}>
                                        <Card {...article} variant="top" onClick={() => onCardClick(article)}/>
                                     </Link>
                                </PopularCard>
                            ))}
                            {!articles.length && [...(Array(4).keys())].map((key) =>
                                <>
                                 <PopularCard>
                                     <Card variant="top"/>
                                 </PopularCard>
                                </>
                            )}
                        </Slider>
                    </Container>
                </SorcererWrapper>
            );
        case 'reading':
            return (
                <SorcererWrapper>
                    <Container>
                        <Title>Продолжить чтение</Title>
                        <Card {...article} variant="top" onClick={() => onCardClick(article)}/>
                    </Container>
                </SorcererWrapper>
            );
        default:
            return (
                <SorcererWrapper>
                    <Container>
                        <Title>В работе</Title>
                    </Container>
                </SorcererWrapper>
            );
    }
}
