import * as types from '../actions/types';

const now = new Date();

const initialState = {
    articles: [],
    articlesUpdatedAt: now,
    related: [],
    popular: [],
    video: [],
    search: [],
    wait: false,
    error: null,
    selected: null,

    popularFilter: 'ALL',
};

export default function article (state = initialState, {type, payload}) {
    switch (type) {
        case types.SET_POPULAR_FILTER:
            return {
                ...state,
                popularFilter: payload,
            }

        // popular
        case types.GET_POPULAR_PORTIONS_START:
            return {
                ...state,
                wait: true,
                error: null,
            };
        case types.GET_POPULAR_PORTIONS_SUCCESS:
            return {
                ...state,
                wait: false,
                popular: payload,
            };
        case types.GET_POPULAR_PORTIONS_ERROR:
            return {
                ...state,
                wait: false,
                error: payload,
            };

        // search
        case types.SEARCH_ARTICLES_START:
            return {
                ...state,
                search: [],
                error: false,
                wait: true,
            };
        case types.SEARCH_ARTICLES_ERROR:
            return {
                ...state,
                error: payload,
                wait: false,
            };
        case types.SEARCH_ARTICLES_SUCCESS:
            return {
                ...state,
                search: payload,
                wait: false,
            };

        // video
        case types.GET_VIDEO_PORTIONS_START:
            return {
                ...state,
                error: false,
                wait: true,
            };
        case types.GET_VIDEO_PORTIONS_ERROR:
            return {
                ...state,
                error: payload,
                wait: false,
            };
        case types.GET_VIDEO_PORTIONS_SUCCESS:
            return {
                ...state,
                video: [...state.video, ...payload],
                wait: false,
            };

        // portions
        case types.GET_ARTICLE_PORTIONS_SUCCESS:
            return {
                ...state,
                wait: false,
                articlesUpdatedAt: new Date(),
                articles: [...state.articles, ...payload],
            };
        case types.GET_ARTICLE_PORTIONS_ERROR:
            return {
                ...state,
                wait: false,
                error: payload,
            };
        case types.GET_ARTICLE_PORTIONS_START:
            return {
                ...state,
                ...payload,
                error: null,
                wait: true,
            };

        case types.ARTICLE_RESET:
            return {
                ...state,
                error: false,
                articles: [],
            };

        // related
        case types.GET_RECOMMENDATIONS_SUCCESS:
            return {
                 ...state,
                related: payload,
                wait: false,
            };
        case types.UNSELECT_ARTICLE:
            return {
                ...state,
                selected: null,
            };

        // detail
        case types.GET_ARTICLE_SUCCESS:
            return {
                ...state,
                wait: false,
                selected: payload,
            };
        case types.GET_ARTICLE_START:
            return {
                ...state,
                wait: true,
                error: null,
            };
        default:
            return state;
    }
}
