import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createMemoryHistory, createBrowserHistory } from 'history';
import createReducers from './reducers';
import logger from 'redux-logger'
import {isServer} from './utils';

export {
  isServer
}

export let history;

const makeStore = (urls = ['/']) => {
  // Create a history depending on the environment
  history = isServer
      ? createMemoryHistory({
        initialEntries: urls,
        initialIndex: 1
      })
      : createBrowserHistory({
        basename: '/',
      });

  const enhancers = [];
  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [thunk, routerMiddleware(history)];
  if (process.env.NODE_ENV === 'development' && !isServer) {
    middleware.push(logger)
  }
  const composedEnhancers = compose(
      applyMiddleware(...middleware),
      ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  const store = createStore(
      createReducers(history),
      initialState,
      composedEnhancers
  );

  return {
    store,
    history
  };
};

export default makeStore
