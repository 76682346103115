import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M17.098 6.326v11.348c0 .741-.588 1.326-1.333 1.326H1.333A1.319 1.319 0 010 17.674V6.326C0 5.585.588 5 1.333 5h14.432c.745 0 1.333.624 1.333 1.326zm6.235.312c.353 0 .667.312.667.663v9.398c0 .195-.04.39-.196.507a.651.651 0 01-.47.195c-.118 0-.236 0-.354-.078L18 14.437V9.563l4.98-2.847a1.15 1.15 0 01.353-.078zM4.97 7.596a.93.93 0 00-.286.67v7.463a.936.936 0 00.842.932c.19.02.381-.017.549-.107l6.895-3.73a.932.932 0 00.494-.822.932.932 0 00-.494-.82L6.074 7.45a.945.945 0 00-1.105.146z"
      />
    </svg>
  );
}

export default Icon;
