import {useState, useEffect} from 'react';
import {isServer} from '../utils';
const getSize = () => (!isServer && ({width: window.innerWidth || 1680, height: window.innerHeight || 947})) || {width: 1680, height: 947};

(function() {
    const throttle = function(type, name, obj) {
        obj = obj || window;
        let running = false;
        const func = () => {
            if (running) {
                return;
            }
            running = true;
            requestAnimationFrame(() => {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };
    !isServer && throttle("resize", "optimizedResize");
})();


export default function useWindowSize () {
    const [size, setSize] = useState(
        getSize
    );

    useEffect(() => {
        const onResize = () => setSize(getSize());
        window.addEventListener('optimizedResize', onResize);
        return () => window.removeEventListener('optimizedResize', onResize);
    });

    return size;
}
