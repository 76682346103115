import React, {useState} from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import {Menu, IconNight, IconSearch} from '../icons';
import {Link, NavLink} from 'react-router-dom';
import {NavbarContext} from '../contexts';
import {useComponentVisible} from '../hooks';
import Container from './container';
import Tag from './tag';
import {FadeIn} from '../styles/animations';

const Nav = styled(({display, ...props}) => (<nav {...props}/>))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 9998;
  
  a {
    opacity: 0.9;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.primary};
    margin: 0 12px;
    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }
    &:focus {
      color: ${({theme}) => theme.colors.primary};
    }
  }  
  
  ${({hide})=> hide && `
        display: none !important;
  `} 
`;

const NavbarContainer = styled(Container)`
  position: relative;
  z-index: 9999 !important;
  transition: all 0.2s;
  max-width: 1087px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media(max-width: 576px) {
    padding: 0 12px;
    width: auto;
  }
`;

const NavbarWrapper = styled(Headroom)`
  font-family: Roboto;
  height: 0 !important;
  z-index: 10000;
  color: #e71c5c;
  ${({theme}) => `
  .headroom {
        div.main {
            background-color: ${theme.colors.navbar};
            box-shadow: ${theme.shadow.light};
            z-index: 9999;
            position: relative;
        }
        
        div.subnavbar {
            background-color: ${theme.colors.sub};

            width: 100%;
            z-index: 8888;
            position: relative;
            box-shadow: ${theme.shadow.dark};
        }
     
       }
  `}; 
  @media (max-width: 768px) {
      nav.links {
          display: none;
      }
  }
`;

const LogoText = styled(Link)`
    font-family: 'Roboto Slab'  !important;
    font-weight: bold  !important;
    font-size: 24px !important;
    color: ${({theme}) => theme.colors.font} !important;
    
    @media (max-width: 576px) {
      ${({hide}) => hide && `display: none;`
    }
    
`;

const LogoLink = styled(Link)`
    margin: 0 !important;
    display: block;
    height: 42px;
    width: 42px;
    background-image: url('${process.env.PUBLIC_URL}/assets/logo_icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const SearchWrapper = styled.form`
    animation: 1s ease-out 0s 1 ${FadeIn};
    background: #fff;
    z-index: 9999;
    display: ${({display}) => !display ? `none` : 'flex'};
    flex-direction: row;
    box-shadow: 0 1px 2px 0 rgba(129, 129, 129, 0.5);
    border-radius: 16px;
    width: 100%;
    // search input
    & div:first-child {
        width: 98%;
        input {
            padding: 7px;
            position: relative;
            display: inline-block;
            box-sizing: content-box;
            height: 35px;
            border: 1px solid #ddd;
            text-overflow: clip;
            z-index: 77;
            border-radius: 16px 0 0 16px;
            padding: 0 5%;
            width: 90%;
            background-color:  ${({theme}) => theme.colors.navbar};
            color: ${({theme}) => theme.colors.primary};
            &:focus {
                box-shadow: inset 0 0 0px 1px ${({theme}) => theme.colors.primary};
                outline-color: rgba(0,0,0,0);
            }
        }
    }
    
    // button
    & div:nth-child(2) {
        div {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 0 16px 16px 0;
            min-height: 37px;
            padding: 5px 1px 0 0;
            svg {
                height: 24px;
            }
        }
    }
    
    @media(max-width: 768px) {
        margin: 0px 6px;
    }
`;

const Hamburger = styled(Tag)`
    position: absolute;
    margin-top: 15px;
    width: 171px;
    z-index: 9999;
    display: none;
    text-transform: uppercase;
    transition: all 0.3s;
    top: 0;
    svg {
        height: 14px !important;
        float: right;

        path {
            fill: #fff;
        }
    }
    
    @media(max-width: 768px){
        ${({theme}) => `
            text-align: left;
            //display: block;
            background-color: ${theme.colors.primary};
            box-shadow: ${theme.shadow.red};
        `}
    }  
`;

const SubNavbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    z-index: 7777;
    position: relative;
    margin: 0 -24px;

    
    @media(min-width: 769px) {
        a.navigation {
            display: none;
        }
    }
    
    a {
        text-transform: uppercase;
        display: inline;
        position: relative;
        border-radius: 4px;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: condensed;
        line-height: normal;
        letter-spacing: 0.91px;
        text-align: center;
        color: #ffffff;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all .3s;
        z-index: 88;
        ${({theme}) => `
            color: ${theme.colors.font};
            @media(min-width: 1024px) {
                &:hover {
                    color: #ffffff;
                    background-color: ${theme.colors.primary};
                    box-shadow: ${theme.shadow.primary};
                }
            }
        `}
    }
    ${({theme}) => `
    & a.active {
        color: ${theme.colors.primary};
        font-size: 18px;
        font-weight: 900;
    }
        
    @media(min-width: 768px) {
         a {
                margin: 15px 15px 15px 0;
         }
         & a.active {
         
                color: #ffffff;
                background-color: ${theme.colors.primary};
                box-shadow: ${theme.shadow.primary};   
             }
        }`
    }
    ${({theme, display}) => `
         
         @media (max-width: 767px) {
            position: absolute;
            height: ${!display ? `0 !important` : `auto`};
            padding: ${!display ? `0` : `20px 15px 15px 15px`};
            border-radius: 4px;
            width: auto;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background-color: ${theme.colors.sub};
            width: 171px;
            margin: 35px 0; 
            
            & a.active {
                color: ${theme.colors.primary};
                font-size: 21px;
                font-weight: 700;
                font-stretch: condensed;
                line-height: 2;
            }
         
            a {
                text-transform: none;
                text-align: left;
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: condensed;
                line-height: 2;
                letter-spacing: normal;
                padding: 0 0 0 5px;
                
                &:not(.active) {
                    color: ${theme.colors.font};
                    &:hover {
                        color: ${theme.colors.primary};
                    }
                 }
             }
         }
         
     `};
`;

const Divider = styled.div`
    background-color: #e71c5c;
    position: relative;
    height: 2px;
    margin: 5px 2px;
`;

function Navbar () {
    const {
        pinStart,
        onVideoClick,
        onPopularClick,
        onLogoClick,
        onSearchClick,
        onChangeThemeClick,
        subNavbar,
        searchInput,
    } = React.useContext(NavbarContext);
    const [search, setSearch] = useState(searchInput);
    const [ref, isComponentVisible, openModal] = useComponentVisible(!!search);
    const [subRef, isSubNavbarVisible, openSubNavbar] = useComponentVisible(false);

    return (
        <NavbarWrapper pinStart={pinStart} calcHeightOnResize disableInlineStyles>
            <div className="main">
            <NavbarContainer>

            <Nav className="logo">
                <LogoLink to="/" onClick={onLogoClick}>

                </LogoLink>
                <LogoText to="/" hide={isComponentVisible ? 'true' : undefined } onClick={onLogoClick}>
                    Стаканчик
                </LogoText>
            </Nav>
                {!isComponentVisible &&
                <Nav className="links" hide={isComponentVisible ? 'true' : undefined }>
                  <NavLink activeClassName="active" onlyActiveOnIndex={true} to="/popular" onClick={onPopularClick}>Популярное</NavLink>
                  <NavLink activeClassName="active" onlyActiveOnIndex={true} to="/video" onClick={onVideoClick}>Видео</NavLink>
                  <NavLink activeClassName="active" onlyActiveOnIndex={true} to="/about">Авторы</NavLink>
                </Nav>
                }
            <Nav className="actions" style={{width: isComponentVisible ? "100%" : "auto"}}>

                <SearchWrapper onSubmit={(e) => {e.preventDefault(); onSearchClick(search)}}
                               ref={ref} display={isComponentVisible ? 'true' : undefined}>
                        <div>
                            <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Поиск"/>
                        </div>
                        <div>
                            <div>
                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                onClick={() => onSearchClick(search)}>
                                    <IconSearch/>
                                </a>
                            </div>
                        </div>
                </SearchWrapper>

                {!isComponentVisible && 
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                        onClick={() => openModal(true)}>
                            <IconSearch/>
                    </a>
                }
                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                    onClick={onChangeThemeClick}>
                    <IconNight/>
                </a>
            </Nav>
            </NavbarContainer>
            </div>
            <div className={`subnavbar ${pinStart === 0 ? 'show': ''}`}>
                <NavbarContainer>

                    {isSubNavbarVisible && <Hamburger className="hamburger" onClick={() => openSubNavbar(false)}>
                        Меню <Menu/>
                    </Hamburger>
                    }

                    {!isSubNavbarVisible && <Hamburger className="hamburger" onClick={() => openSubNavbar(true)}>
                        Меню <Menu/>
                    </Hamburger>
                    }

                    <SubNavbar ref={subRef} onClick={() => openSubNavbar(false)} display={isSubNavbarVisible ? 'true' : undefined}>
                                <NavLink className="navigation" activeClassName="active"
                                    to="/popular" onClick={onPopularClick}>Популярное</NavLink>
                                <NavLink className="navigation" activeClassName="active"
                                    to="/video" onClick={onVideoClick}>Видео</NavLink>
                                <NavLink className="navigation" activeClassName="active"
                                    to="/about">Авторы</NavLink>
                        {subNavbar && <Divider/>}
                        {subNavbar}
                    </SubNavbar>

                </NavbarContainer>
            </div>
          </NavbarWrapper>
    )
}

export default Navbar;

