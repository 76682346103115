import React from 'react';

export default React.createContext({
    onVideoClick: () => {},
    onPopularClick: () => {},
    onLogoClick: () => {},
    onSearchClick: (q) => {},
    setSubNavbar: () => {},
    onChangeThemeClick: () => {},
    setPinStart: (n) => {},
    searchInput: "",
    pinStart: 0,
    subnavbar: null,
})