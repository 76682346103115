import React from 'react'

const Menu = ({color='#0063FF'}) => (
<svg xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 459 459">
    <path d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z"
    id="menu" />
</svg>
);

export default Menu;
